var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "section",
    {
      staticClass: "twoActive",
    },
    [
      _c(
        "div",
        {
          staticClass: "twoActive-wrap",
        },
        [
          _c(
            "div",
            {
              staticClass: "twoActive-container",
            },
            [
              _c("div", [
                _vm._m(0),
                _c(
                  "div",
                  {
                    staticClass: "learnReason-grid",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "learnReason-card",
                      },
                      [
                        _c("div", [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/newYear_2024/twoActive/grow1.png"),
                              alt: "全英文環境",
                            },
                          }),
                          _c(
                            "h3",
                            {
                              staticClass: "learnReason-scondary",
                            },
                            [_vm._v("全英文環境")]
                          ),
                          _c("img", {
                            staticClass: "dec",
                            attrs: {
                              src: require("@/assets/event/trial/dec.png"),
                            },
                          }),
                          _vm._m(1),
                        ]),
                        _c("span", {
                          staticClass: "leaf leaf-M",
                        }),
                        _c("span", {
                          staticClass: "leaf leaf-L",
                        }),
                        _c("span", {
                          staticClass: "leaf leaf-S",
                        }),
                      ]
                    ),
                    _c("img", {
                      staticStyle: {
                        margin: "0 auto",
                      },
                      attrs: {
                        src: require("@/assets/newYear_2024/icon/plus.svg"),
                        alt: "plus",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "learnReason-card",
                      },
                      [
                        _c("div", [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/newYear_2024/twoActive/grow2.png"),
                            },
                          }),
                          _c(
                            "h3",
                            {
                              staticClass: "learnReason-scondary",
                            },
                            [_vm._v("穩定高頻率學習")]
                          ),
                          _c("img", {
                            staticClass: "dec",
                            attrs: {
                              src: require("@/assets/event/trial/dec.png"),
                            },
                          }),
                          _vm._m(2),
                        ]),
                        _c("span", {
                          staticClass: "leaf leaf-M",
                        }),
                        _c("span", {
                          staticClass: "leaf leaf-L",
                        }),
                        _c("span", {
                          staticClass: "leaf leaf-S",
                        }),
                      ]
                    ),
                    _c("img", {
                      staticStyle: {
                        margin: "0 auto",
                      },
                      attrs: {
                        src: require("@/assets/newYear_2024/icon/plus.svg"),
                        alt: "plus",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "learnReason-card",
                      },
                      [
                        _c("div", [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/newYear_2024/twoActive/grow3.png"),
                            },
                          }),
                          _c(
                            "h3",
                            {
                              staticClass: "learnReason-scondary",
                            },
                            [_vm._v("有趣學習 愛上英文")]
                          ),
                          _c("img", {
                            staticClass: "dec",
                            attrs: {
                              src: require("@/assets/event/trial/dec.png"),
                            },
                          }),
                          _vm._m(3),
                        ]),
                        _c("span", {
                          staticClass: "leaf leaf-M",
                        }),
                        _c("span", {
                          staticClass: "leaf leaf-L",
                        }),
                        _c("span", {
                          staticClass: "leaf leaf-S",
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._m(4),
                _vm._m(5),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "title-block",
      },
      [
        _c("img", {
          staticClass: "title-img",
          attrs: {
            src: require("@/assets/newYear_2024/icon/loudspeaker.png"),
            alt: "loudspeaker",
          },
        }),
        _c(
          "div",
          {
            staticClass: "title-content",
          },
          [_c("h1", [_vm._v("雙語兒童養成辦法")])]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c("p", [
      _vm._v("在家與學校創造全英語氛圍，"),
      _c("br"),
      _vm._v("促進孩子在生活中輕鬆自然地"),
      _c("br"),
      _vm._v("運用英文。"),
    ])
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c("p", [
      _vm._v("每週穩定、頻繁學習英文，養"),
      _c("br"),
      _vm._v("成自然而然的語感。"),
    ])
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c("p", [
      _vm._v("利用英文卡通、音樂等活動，"),
      _c("br"),
      _vm._v("培養孩子對英文的愛好，無壓"),
      _c("br"),
      _vm._v("有趣學習。"),
    ])
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "arrow-down",
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/newYear_2024/icon/arrow-down.png"),
            alt: "arrow-down",
          },
        }),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "junior-english",
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/newYear_2024/twoActive/junior-english.png"),
            alt: "junior-english",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }