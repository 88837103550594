import { render, staticRenderFns } from "./FeatureComparison.vue?vue&type=template&id=0a62b1f2&scoped=true&lang=pug"
var script = {}
import style0 from "./FeatureComparison.vue?vue&type=style&index=0&id=0a62b1f2&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a62b1f2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspaces/wuwow-junior-vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0a62b1f2')) {
      api.createRecord('0a62b1f2', component.options)
    } else {
      api.reload('0a62b1f2', component.options)
    }
    module.hot.accept("./FeatureComparison.vue?vue&type=template&id=0a62b1f2&scoped=true&lang=pug", function () {
      api.rerender('0a62b1f2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/event/newYear2024/components/FeatureComparison.vue"
export default component.exports