var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "section",
    {
      staticClass: "banner",
    },
    [
      _c(
        "div",
        {
          staticClass: "banner-wrap",
        },
        [_vm._m(0), _c("SignForm")],
        1
      ),
      _vm._m(1),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "banner-title",
      },
      [
        _c("img", {
          staticClass: "d-inline-block",
          attrs: {
            src: require("@/assets/newYear_2024/banner/title.svg"),
            alt: "雙語兒童養成技",
          },
        }),
        _c("p", [
          _vm._v("超越時空、多元有趣的 WUWOW Jr."),
          _c("br"),
          _vm._v("營造輕鬆愉快沈浸式英文環境！"),
        ]),
        _c(
          "div",
          {
            staticClass: "banner-rewards",
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/newYear_2024/banner/disney.svg"),
                alt: "一年份Disney+",
              },
            }),
          ]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "banner-disney",
      },
      [
        _c(
          "div",
          {
            staticClass: "title-block",
          },
          [
            _c("img", {
              staticClass: "title-img",
              attrs: {
                src: require("@/assets/newYear_2024/icon/loudspeaker.png"),
                alt: "loudspeaker",
              },
            }),
            _c(
              "div",
              {
                staticClass: "title-content",
              },
              [_c("h1", [_vm._v("追尋夢想，買就送Disney+")])]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "disney-box",
          },
          [
            _c("h2", [
              _vm._v("購買任一學習方案，"),
              _c("br"),
              _vm._v("贈送 "),
              _c("span", [_vm._v("1 年 Disney+")]),
              _vm._v("標準方案"),
            ]),
            _c("p", [
              _vm._v("開啟兼顧"),
              _c("span", [_vm._v("「有效")]),
              _vm._v("」與"),
              _c("span", [_vm._v("「有趣」")]),
              _vm._v("的英文學習旅程，"),
              _c("br"),
              _vm._v("讓休閒生活也能很英文！"),
            ]),
            _c("img", {
              attrs: {
                src: require("@/assets/newYear_2024/banner/disney-free-l.png"),
                alt: "disney-free",
              },
            }),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }