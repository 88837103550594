import { render, staticRenderFns } from "./Ribbons.vue?vue&type=template&id=568f2ac5&scoped=true&lang=pug"
import script from "./Ribbons.vue?vue&type=script&lang=js"
export * from "./Ribbons.vue?vue&type=script&lang=js"
import style0 from "./Ribbons.vue?vue&type=style&index=0&id=568f2ac5&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "568f2ac5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspaces/wuwow-junior-vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('568f2ac5')) {
      api.createRecord('568f2ac5', component.options)
    } else {
      api.reload('568f2ac5', component.options)
    }
    module.hot.accept("./Ribbons.vue?vue&type=template&id=568f2ac5&scoped=true&lang=pug", function () {
      api.rerender('568f2ac5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/event/newYear2024/components/Ribbons.vue"
export default component.exports