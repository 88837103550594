var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "section",
    {
      staticClass: "test-your-rep",
    },
    [
      _vm._m(0),
      _vm._m(1),
      _c("img", {
        staticClass: "one-year-disney",
        attrs: {
          src: require("@/assets/newYear_2024/test-your-rep/one-year-disney-v2.png"),
          alt: "一年份迪士尼+",
        },
      }),
      _c(
        "button",
        {
          staticClass: "go-to-test",
          attrs: {
            type: "button",
          },
          on: {
            click: _vm.goToTest,
          },
        },
        [_vm._v("前往測驗")]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "title-block",
      },
      [
        _c("img", {
          staticClass: "title-img",
          attrs: {
            src: require("@/assets/newYear_2024/icon/loudspeaker.png"),
            alt: "loudspeaker",
          },
        }),
        _c(
          "div",
          {
            staticClass: "title-content",
          },
          [
            _c("h1", [
              _vm._v(" 來測測你寶貝適合的 "),
              _c("span", [_vm._v("迪士尼經典人物專屬學習方式")]),
            ]),
          ]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "test-content",
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/newYear_2024/test-your-rep/miki.png"),
            alt: "米奇",
          },
        }),
        _c("img", {
          attrs: {
            src: require("@/assets/newYear_2024/test-your-rep/bear.png"),
            alt: "維尼",
          },
        }),
        _c("img", {
          attrs: {
            src: require("@/assets/newYear_2024/test-your-rep/mini.png"),
            alt: "米妮",
          },
        }),
        _c("img", {
          attrs: {
            src: require("@/assets/newYear_2024/test-your-rep/pig.png"),
            alt: "小豬",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }