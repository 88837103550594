var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "form",
    {
      staticClass: "signUp",
      class: _vm.styleData,
    },
    [
      _c("h2", [_vm._v("報名領取")]),
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "signUp-form",
        },
        [
          _c(
            "div",
            {
              staticClass: "signUp-input",
            },
            [
              _vm._m(1),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formUpper.parentName,
                    expression: "formUpper.parentName",
                  },
                ],
                attrs: {
                  type: "text",
                  placeholder: "家長姓名 (必填)",
                },
                domProps: {
                  value: _vm.formUpper.parentName,
                },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formUpper, "parentName", $event.target.value)
                  },
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "signUp-input",
            },
            [
              _vm._m(2),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formUpper.parentPhone,
                    expression: "formUpper.parentPhone",
                  },
                ],
                attrs: {
                  type: "text",
                  placeholder: "家長手機 (必填)",
                },
                domProps: {
                  value: _vm.formUpper.parentPhone,
                },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formUpper, "parentPhone", $event.target.value)
                  },
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "signUp-input",
            },
            [
              _vm._m(3),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formUpper.parentEmail,
                    expression: "formUpper.parentEmail",
                  },
                ],
                attrs: {
                  type: "text",
                  placeholder: "家長信箱 (必填)",
                },
                domProps: {
                  value: _vm.formUpper.parentEmail,
                },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formUpper, "parentEmail", $event.target.value)
                  },
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "signUp-input",
            },
            [
              _vm._m(4),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formUpper.keyword,
                    expression: "formUpper.keyword",
                  },
                ],
                attrs: {
                  type: "text",
                  placeholder: "通關密語",
                },
                domProps: {
                  value: _vm.formUpper.keyword,
                },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formUpper, "keyword", $event.target.value)
                  },
                },
              }),
            ]
          ),
          _vm._m(5),
          _c(
            "div",
            {
              staticClass: "privacy",
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formUpper.privacyPolicyCheck,
                    expression: "formUpper.privacyPolicyCheck",
                  },
                ],
                staticClass: "d-none",
                attrs: {
                  type: "checkbox",
                  id: "privacy",
                },
                domProps: {
                  checked: Array.isArray(_vm.formUpper.privacyPolicyCheck)
                    ? _vm._i(_vm.formUpper.privacyPolicyCheck, null) > -1
                    : _vm.formUpper.privacyPolicyCheck,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.formUpper.privacyPolicyCheck,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.formUpper,
                            "privacyPolicyCheck",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.formUpper,
                            "privacyPolicyCheck",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.formUpper, "privacyPolicyCheck", $$c)
                    }
                  },
                },
              }),
              _c(
                "label",
                {
                  attrs: {
                    for: "privacy",
                  },
                },
                [
                  _vm._v("我同意 WUWOW 線上英文"),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          path: _vm.privacyUrl,
                        },
                        target: "_blank",
                      },
                    },
                    [_vm._v("隱私權政策")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "button",
        {
          staticClass: "signUp-btn",
          attrs: {
            type: "button",
          },
          on: {
            click: function ($event) {
              return _vm.submitForm(_vm.formUpper)
            },
          },
        },
        [_vm._v("免費搶先預約")]
      ),
      _vm._m(6),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "p",
      {
        staticClass: "text-center",
      },
      [
        _c(
          "span",
          {
            staticClass: "drawingPoint",
          },
          [_vm._v("英文能力分析報告")]
        ),
        _vm._v("＆ 實戰乾貨"),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "icon",
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/newYear_2024/icon/person.svg"),
            alt: "person",
          },
        }),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "icon",
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/newYear_2024/icon/phone.svg"),
            alt: "phone",
          },
        }),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "icon",
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/newYear_2024/icon/mail.svg"),
            alt: "mail",
          },
        }),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "icon",
      },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/newYear_2024/icon/key.svg"),
            alt: "key",
          },
        }),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "materials",
      },
      [
        _c(
          "div",
          {
            staticClass: "w-50",
          },
          [
            _c("picture", [
              _c("source", {
                attrs: {
                  srcset: require("@/assets/newYear_2024/form/gift1-pc.png"),
                  media: "(min-width: 1024px)",
                },
              }),
              _c("source", {
                attrs: {
                  srcset: require("@/assets/newYear_2024/form/gift1-tablet.png"),
                  media: "(min-width: 768px)",
                },
              }),
              _c("img", {
                attrs: {
                  src: require("@/assets/newYear_2024/form/gift1-tablet.png"),
                  alt: "家長必讀雙語兒童養成辦法",
                },
              }),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "w-50",
          },
          [
            _c("picture", [
              _c("source", {
                attrs: {
                  srcset: require("@/assets/newYear_2024/form/gift2-pc.png"),
                  media: "(min-width: 1024px)",
                },
              }),
              _c("source", {
                attrs: {
                  srcset: require("@/assets/newYear_2024/form/gift2-tablet.png"),
                  media: "(min-width: 768px)",
                },
              }),
              _c("img", {
                attrs: {
                  src: require("@/assets/newYear_2024/form/gift2-tablet.png"),
                  alt: "劍橋兒童英檢題庫",
                },
              }),
            ]),
          ]
        ),
      ]
    )
  } /* staticRenderFnsSpliter */,
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "p",
      {
        staticClass: "contactNumber",
      },
      [
        _c("span", [_vm._v("0800 885 315")]),
        _vm._v("撥打專線，立即有專人為您服務"),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }